import Masks from './masks'
import CityTypeahead from './city_typeahead'

export default class PublicScripts {
  run () {
    this.runMasks()
    this.runCityTypeahead()
  }

  runMasks () {
    return new Masks()
  }

  runCityTypeahead () {
    return new CityTypeahead()
  }
}
