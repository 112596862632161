import Typeahead from './typeahead'

export default class CityTypeahead {
  constructor () {
    this.inputCityId = $('#person_city_id') // eslint-disable-line

    this.config = {
      displayKey: 'full_name',
      hookField: '#person_city',
      remoteUrl: '/cities/search/%QUERY',
      templates: {
        empty: this.emptyTemplate(),
        suggestion: this.suggestionTemplate()
      },
      callbacks: {
        change: $.proxy(this.onChange, this), // eslint-disable-line
        selected: $.proxy(this.onSelect, this) // eslint-disable-line
      }
    }
    this.runTypeahead(this.config)
  }

  runTypeahead (options) {
    return new Typeahead(options)
  }

  emptyTemplate () {
    return [
      '<div class="suggestion-details">',
      '<div class="suggestion-infos">',
      'Nenhum registro encontrado',
      '</div>',
      '</div>'
    ].join('\n')
  }

  suggestionTemplate () {
    return [
      '<div class="suggestion-details">',
      '<div class="suggestion-name">{{full_name}}</div>',
      '</div>'
    ].join('\n')
  }

  onChange (e) {
    if (!$(e.target).val().length) { // eslint-disable-line
      this.inputCityId.val('')
    }

    if (!this.inputCityId.val().length) {
      $('#person_city').typeahead('val', '')
    }
  }

  onSelect (obj, datum) {
    this.inputCityId.val(datum.id)
  }
}
