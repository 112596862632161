export default class Masks {
  constructor () {
    this.reloadMasks()
  }

  reloadMasks () {
    $(':input.cpf').inputmask('999.999.999-99') // eslint-disable-line
    $(':input.cnpj').inputmask('99.999.999/9999-99') // eslint-disable-line
    $(':input.date').inputmask('99/99/9999') // eslint-disable-line
    $(':input.zipcode').inputmask('99999-999') // eslint-disable-line
    $(':input.phone').inputmask(['(99) 9999-9999', '(99) 99999-9999']) // eslint-disable-line
  }
}
