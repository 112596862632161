import Bloodhound from 'corejs-typeahead/dist/bloodhound.min'
import Handlebars from 'handlebars/dist/handlebars'

export default class Typeahead {
  constructor (options) {
    this.options = options
    this.agenciesDataset = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.whitespace('value'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      remote: { url: this.options.remoteUrl, wildcard: '%QUERY' }
    })

    this.run()
  }

  run () {
    $(this.options.hookField).typeahead(null, { // eslint-disable-line
      display: this.options.displayKey,
      source: this.agenciesDataset,
      templates: {
        empty: this.options.templates.empty,
        suggestion: Handlebars.compile(this.options.templates.suggestion)
      }
    }).bind('typeahead:selected', (obj, datum, name) => {
      if (typeof this.options.callbacks.selected === 'function') {
        this.options.callbacks.selected(obj, datum, name)
      }
    }).bind('change', (e) => {
      if (typeof this.options.callbacks.change === 'function') {
        this.options.callbacks.change(e)
      }
    })
  }
}
